import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { FaFacebookF, FaInstagram, FaWhatsapp } from "react-icons/fa";
import img_1 from "../orla_lp/orla_1.jpeg";
import img_2 from "../orla_lp/orla_2.jpeg";
import img_3 from "../orla_lp/orla_3.jpeg";
import img_4 from "../orla_lp/orla_4.jpeg";
import img_5 from "../orla_lp/orla_5.jpeg";
import img_6 from "../orla_lp/orla_6.jpeg";
import img_7 from "../orla_lp/orla_7.jpeg";

const HomePage = () => {
  return (
    <div className="home-container">
      <div className="home__data">
        <h2 className="home__subtitle">NOSSO</h2>

        <h1 className="home__title">Espaço</h1>

        <p className="home__description">
          Complexo de quadras para práticas de Beach Tennis.<br></br>
          Oferecemos locação de quadras, aulas, campeanatos, espaço de lazer,
          espaço kids, estacionamento privativo, bar/restaurante e eventos.
        </p>

        <a href="/reservar" className="home__button">
          RESERVE AGORA
        </a>
      </div>
      <div className="carousel-section">
        <Carousel>
          <Carousel.Item>
            <img className="d-block w-100" src={img_1} alt="Imagem 1" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={img_2} alt="Imagem 2" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={img_3} alt="Imagem 3" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={img_4} alt="Imagem 4" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={img_5} alt="Imagem 5" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={img_6} alt="Imagem 6" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={img_7} alt="Imagem 7" />
          </Carousel.Item>
        </Carousel>
      </div>
      <div className="social-icons">
        <a
          href="https://www.instagram.com/orla.beachtennisourinhos/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFacebookF size={32} />
        </a>
        <a
          href="https://www.instagram.com/orla.beachtennisourinhos/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaInstagram size={32} />
        </a>
        <a
          href="https://wa.me/5514996897928"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaWhatsapp size={32} />
        </a>
      </div>
    </div>
  );
};

export default HomePage;
