import React from "react";
import logo from "./logo.png";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import ContactInfo from "./components/ContactInfo";
import Booking from "./components/Booking";
import PublicNavBar from "./components/PublicNavBar";
import AdminNavbar from "./components/AdminNavBar";
import Court from "./components/Court";
import CalendarPage from "./components/CalendarPage";
import AdditionalInfo from "./components/AdditionalInfo";
import HomePage from "./components/HomePage";
import MyReservations from "./components/MyReservations";
import FileExplorer from "./components/FileExplorer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CourtDetails from "./components/CourtDetails"; // View court details
import EditCourt from "./components/EditCourt"; // Edit court details
import AddCourt from "./components/AddCourt"; // Add new court
import ImageGallery from "./components/ImageGallery";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route
            exact
            path="/reservar"
            element={
              <>
                <PublicNavBar />
                <header className="App-header">
                  <br />
                  <img src={logo} className="App-logo" alt="logo" />
                  <ContactInfo />
                  <AdditionalInfo />
                  <Booking />
                </header>
              </>
            }
          />
          <Route
            exact
            path="/"
            element={
              <>
                <PublicNavBar />
                <HomePage />
              </>
            }
          />
          <Route
            exact
            path="/meus-lances"
            element={
              <>
                <PublicNavBar />
                <FileExplorer />
              </>
            }
          />
          <Route
            exact
            path="/minhas-reservas"
            element={
              <>
                <PublicNavBar />
                <MyReservations />
              </>
            }
          />
          <Route
            exact
            path="/parceiros"
            element={
              <>
                <PublicNavBar />
                <ImageGallery />
              </>
            }
          />
          <Route
            exact
            path="/576eda7ff65a385db4e87794a2cf47cc6cc5d0b89da8c12b85386931eccea823"
            element={
              <>
                <AdminNavbar />
                <Court />
              </>
            }
          />
          <Route
            exact
            path="/2bd729ca23fb5d02c0ba02d401ef8ab810f09a8fafa6649d0c9098bfcc65f1ce"
            element={
              <>
                <AdminNavbar />
                <CalendarPage />
              </>
            }
          />
          <Route
            exact
            path="/576eda7ff65a385db4e87794a2cf47cc6cc5d0b89da8c12b85386931eccea823/:courtId"
            element={
              <>
                <AdminNavbar />
                <CourtDetails />
              </>
            }
          />
          <Route
            exact
            path="/576eda7ff65a385db4e87794a2cf47cc6cc5d0b89da8c12b85386931eccea823/editar-quadra/:courtId"
            element={
              <>
                <AdminNavbar />
                <EditCourt />
              </>
            }
          />
          <Route
            exact
            path="/576eda7ff65a385db4e87794a2cf47cc6cc5d0b89da8c12b85386931eccea823/nova-quadra"
            element={
              <>
                <AdminNavbar />
                <AddCourt />
              </>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
