// CourtDetails.js
import axios from "axios";
import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import API_URL from "../config";

export default function CourtDetails() {
  const { courtId } = useParams();
  const [court, setCourt] = useState(null);

  useEffect(() => {
    loadCourtDetails();
  }, []);

  const loadCourtDetails = async () => {
    const result = await axios.get(`${API_URL}/api/public/court/${courtId}`);
    setCourt(result.data);
  };

  if (!court) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container">
      <h2>Detalhes da Quadra</h2>
      <table className="table">
        <tbody>
          <tr>
            <td>ID</td>
            <td>{court.id}</td>
          </tr>
          <tr>
            <td>Nome</td>
            <td>{court.name}</td>
          </tr>
          <tr>
            <td>Preço</td>
            <td>{"R$" + court.price.toFixed(2)}</td>
          </tr>
          <tr>
            <td>Status</td>
            <td>{court.active ? "Ativa" : "Inativa"}</td>
          </tr>
        </tbody>
      </table>
      <Link
        className="btn btn-outline-primary"
        to={`/576eda7ff65a385db4e87794a2cf47cc6cc5d0b89da8c12b85386931eccea823/editar-quadra/${courtId}`}
      >
        Editar
      </Link>
      <Link
        className="btn btn-outline-secondary"
        to="/576eda7ff65a385db4e87794a2cf47cc6cc5d0b89da8c12b85386931eccea823"
      >
        Voltar
      </Link>
    </div>
  );
}
