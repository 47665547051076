import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import API_URL from "../config";

export default function AddCourt() {
  const [court, setCourt] = useState({ name: "", price: "", active: false });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCourt((prev) => ({
      ...prev,
      [name]: name === "active" ? e.target.checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${API_URL}/api/public/court`, court);
      navigate(
        "/576eda7ff65a385db4e87794a2cf47cc6cc5d0b89da8c12b85386931eccea823"
      );
    } catch (error) {
      console.error("Erro ao adicionar a quadra:", error);
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Adicionar Nova Quadra</h2>
      <form onSubmit={handleSubmit} className="border p-4 rounded shadow">
        <div className="mb-3">
          <label className="form-label">Nome</label>
          <input
            type="text"
            className="form-control"
            name="name"
            value={court.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Preço</label>
          <input
            type="number"
            className="form-control"
            name="price"
            value={court.price}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">
            <input
              type="checkbox"
              name="active"
              checked={court.active}
              onChange={handleChange}
              className="form-check-input me-2"
            />
            Ativa
          </label>
        </div>
        <div className="text-center">
          <button type="submit" className="btn btn-primary me-2">
            Adicionar
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() =>
              navigate(
                "/576eda7ff65a385db4e87794a2cf47cc6cc5d0b89da8c12b85386931eccea823"
              )
            }
          >
            Cancelar
          </button>
        </div>
      </form>
    </div>
  );
}
