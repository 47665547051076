import React from "react";
import logo from "../orla_white_navbar.png";
import { NavLink } from "react-router-dom";

export default function AdminNavbar() {
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark bg-custom">
        <style>{`
          .bg-custom {
            background-color: #274C58; /* Aqui está a definição da cor */
            z-index: 1000;
          }
          .nav-link.active {
            color: #f8f9fa; /* Define a cor da link ativo */
          }
        `}</style>
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img
              src={logo}
              alt="Logo"
              style={{ width: "60px", height: "auto" }}
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/2bd729ca23fb5d02c0ba02d401ef8ab810f09a8fafa6649d0c9098bfcc65f1ce"
                >
                  Reservas
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/576eda7ff65a385db4e87794a2cf47cc6cc5d0b89da8c12b85386931eccea823"
                >
                  Quadras
                </NavLink>
              </li>
            </ul>
            <ul className="navbar-nav mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink className="nav-link" to="/reservar">
                  Logout
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
