import React from "react";
import "./css/ImageGallery.css"; // Arquivo de estilos

const ImageGallery = () => {
  const numberOfImages = 57; // Ajuste conforme a quantidade de imagens

  // Gera os caminhos das imagens dinamicamente
  const images = Array.from({ length: numberOfImages }, (_, index) =>
    require(`../assets/images/Parceiro ${index + 1}.png`)
  );

  return (
    <div>
      <h1 className="gallery-header">Parceiros</h1>
      <div className="gallery-container">
        {images.map((image, index) => (
          <div className="gallery-item" key={index}>
            <img src={image} alt={`Parceiro ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageGallery;
