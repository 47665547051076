import React, { useState, useEffect } from "react";
import axios from "axios";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import API_URL from "../config";

const FileExplorer = () => {
  const [data, setData] = useState([]);
  const [currentPath, setCurrentPath] = useState([]);
  const [viewMode, setViewMode] = useState("quadras");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/public/matchVideo`);
        setData(response.data);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    };

    fetchData();
  }, []);

  const handleNavigation = (path) => {
    setCurrentPath(path);
    if (path.length === 1) {
      setViewMode("days");
    } else if (path.length === 2) {
      setViewMode("hours");
    } else if (path.length === 3) {
      setViewMode("files");
    }
  };

  const renderContent = () => {
    if (viewMode === "files") {
      return (
        <div className="files-container">
          {data
            .filter((video) => {
              const [quadra, day, hour] = currentPath;
              return (
                video.court === quadra &&
                video.date === day &&
                video.hour.split(":")[0] + ":00h" === hour
              );
            })
            .map((video) => (
              <div key={video.id} className="file-item">
                <img
                  src={video.thumbnail_url}
                  alt={video.title}
                  className="thumbnail"
                />
                <p>{video.court + " - " + video.date + " - " + video.hour}</p>{" "}
                {/* Exibe o título do arquivo acima */}
                <div className="file-actions">
                  <a
                    href={video.view_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="action-button">
                      <VisibilityIcon />
                      <span> Visualizar</span>
                    </button>
                  </a>
                  <a
                    href={video.download_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="action-button">
                      <CloudDownloadIcon />
                      <span> Download</span>
                    </button>
                  </a>
                </div>
              </div>
            ))}
        </div>
      );
    }

    const uniqueOptions = (field, filter = () => true, formatFn) => {
      const allOptions = data.filter(filter).map((video) => formatFn(video));
      const uniqueSortedOptions = [...new Set(allOptions)].sort(); // Remove duplicatas e ordena
      return uniqueSortedOptions.map((option) => (
        <button
          key={option}
          onClick={() => handleNavigation([...currentPath, option])}
          className="navigation-button"
        >
          {option}
        </button>
      ));
    };

    if (viewMode === "quadras") {
      return uniqueOptions(
        "court",
        () => true,
        (video) => video.court
      );
    } else if (viewMode === "days") {
      return uniqueOptions(
        "date",
        (video) => video.court === currentPath[0],
        (video) => video.date
      );
    } else if (viewMode === "hours") {
      return uniqueOptions(
        "hour",
        (video) =>
          video.court === currentPath[0] && video.date === currentPath[1],
        (video) => video.hour.split(":")[0] + ":00h"
      ); // Ignora minutos e segundos, agrupa por hora
    }
  };

  return (
    <div className="file-explorer">
      <div className="header">Meus Lances</div>
      {currentPath.length > 0 && (
        <button
          onClick={() => {
            const newPath = currentPath.slice(0, -1);
            setCurrentPath(newPath);
            if (newPath.length === 0) {
              setViewMode("quadras");
            } else if (newPath.length === 1) {
              setViewMode("days");
            } else if (newPath.length === 2) {
              setViewMode("hours");
            }
          }}
          className="back-button"
        >
          Voltar
        </button>
      )}
      {renderContent()}
    </div>
  );
};

export default FileExplorer;
