import axios from "axios";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import API_URL from "../config";

export default function Court() {
  const [courts, setCourts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    loadCourts();
  }, []);

  const loadCourts = async () => {
    try {
      const result = await axios.get(`${API_URL}/api/public/court`);
      setCourts(result.data);
    } catch (error) {
      console.error("Erro ao carregar as quadras:", error);
    }
  };

  const handleView = (courtId) => {
    navigate(
      `/576eda7ff65a385db4e87794a2cf47cc6cc5d0b89da8c12b85386931eccea823/${courtId}`
    );
  };

  const handleEdit = (courtId) => {
    navigate(
      `/576eda7ff65a385db4e87794a2cf47cc6cc5d0b89da8c12b85386931eccea823/editar-quadra/${courtId}`
    );
  };

  const handleDelete = async (courtId) => {
    const confirmDelete = window.confirm(
      "Tem certeza que deseja excluir esta quadra?"
    );
    if (confirmDelete) {
      try {
        await axios.delete(`${API_URL}/api/public/court/${courtId}`);
        loadCourts(); // Reload the court data after deletion
      } catch (error) {
        console.error("Erro ao excluir a quadra:", error);
      }
    }
  };

  // Ordenar as quadras pelo nome
  const sortedCourts = [...courts].sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div className="container">
      <section style={{ marginTop: "30px" }}>
        <h2>Quadras</h2>
      </section>
      <div className="new-court">
        <Link
          className="btn btn-outline-dark"
          to="/576eda7ff65a385db4e87794a2cf47cc6cc5d0b89da8c12b85386931eccea823/nova-quadra"
        >
          Nova Quadra
        </Link>
      </div>
      <table className="table border shadow">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Nome</th>
            <th scope="col">Preço</th>
            <th scope="col">Status</th>
            <th scope="col">Ações</th>
          </tr>
        </thead>
        <tbody>
          {sortedCourts.map((court) => (
            <tr key={court.id}>
              <td>{court.id}</td>
              <td>{court.name}</td>
              <td>{"R$" + court.price.toFixed(2)}</td>
              <td>{court.active ? "Ativa" : "Inativa"}</td>
              <td>
                <button
                  className="btn btn-primary mx-2"
                  onClick={() => handleView(court.id)}
                >
                  Ver
                </button>
                <button
                  className="btn btn-outline-primary mx-2"
                  onClick={() => handleEdit(court.id)}
                >
                  Editar
                </button>
                <button
                  className="btn btn-danger mx-2"
                  onClick={() => handleDelete(court.id)}
                >
                  Excluir
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
