import axios from "axios";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import API_URL from "../config";

export default function EditCourt() {
  const { courtId } = useParams();
  const [court, setCourt] = useState({ name: "", price: "", active: false });
  const navigate = useNavigate();

  useEffect(() => {
    loadCourtDetails();
  }, []);

  const loadCourtDetails = async () => {
    const result = await axios.get(`${API_URL}/api/public/court/${courtId}`);
    setCourt(result.data);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCourt((prev) => ({
      ...prev,
      [name]: name === "active" ? e.target.checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${API_URL}/api/public/court/${courtId}`, court);
      navigate(
        "/576eda7ff65a385db4e87794a2cf47cc6cc5d0b89da8c12b85386931eccea823"
      );
    } catch (error) {
      console.error("Erro ao atualizar a quadra:", error);
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Editar Quadra</h2>
      <form onSubmit={handleSubmit} className="border p-4 rounded shadow">
        <div className="mb-3">
          <label className="form-label">Nome</label>
          <input
            type="text"
            className="form-control"
            name="name"
            value={court.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Preço</label>
          <input
            type="number"
            className="form-control"
            name="price"
            value={court.price}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">
            <input
              type="checkbox"
              name="active"
              checked={court.active}
              onChange={handleChange}
              className="form-check-input me-2"
            />
            Ativa
          </label>
        </div>
        <div className="text-center">
          <button type="submit" className="btn btn-primary me-2">
            Salvar
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() =>
              navigate(
                "/576eda7ff65a385db4e87794a2cf47cc6cc5d0b89da8c12b85386931eccea823"
              )
            }
          >
            Cancelar
          </button>
        </div>
      </form>
    </div>
  );
}
