import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment/locale/pt-br";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Modal from "react-modal";
import API_URL from "../config";

moment.locale("pt-br");

var defaultMessages = {
  date: "Data",
  time: "Hora",
  event: "Evento",
  allDay: "Dia Todo",
  week: "Semana",
  work_week: "Eventos",
  day: "Dia",
  month: "Mês",
  previous: "Anterior",
  next: "Próximo",
  yesterday: "Ontem",
  tomorrow: "Amanhã",
  today: "Hoje",
  agenda: "Agenda",
  noEventsInRange: "Não há eventos no período.",
  showMore: function showMore(total) {
    return "+" + total + " mais";
  },
};

const localizer = momentLocalizer(moment);

const fetchReservations = async () => {
  const response = await fetch(`${API_URL}/api/public/reservation`);
  return response.json();
};

const fetchCustomer = async (customerId) => {
  const response = await fetch(`${API_URL}/api/public/customer/${customerId}`);
  return response.json();
};

const fetchAvailability = async (availabilityId) => {
  const response = await fetch(
    `${API_URL}/api/public/availability/${availabilityId}`
  );
  return response.json();
};

const fetchCourt = async (courtId) => {
  const response = await fetch(`${API_URL}/api/public/court/${courtId}`);
  return response.json();
};

const updateReservationStatus = async (reservationId, status) => {
  const response = await fetch(
    `${API_URL}/api/public/reservation/${reservationId}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ status }),
    }
  );
  return response.json();
};

Modal.setAppElement("#root");

export default function CalendarPage() {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [eventDetails, setEventDetails] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    const getReservations = async () => {
      const reservations = await fetchReservations();
      const events = await Promise.all(
        reservations.map(async (reservation) => {
          const availability = await fetchAvailability(
            reservation.idAvailability
          );
          const court = await fetchCourt(reservation.idCourt);
          const customer = await fetchCustomer(reservation.idCustomer);
          const customerFirstName = customer.name.split(" ")[0]; // Pega o primeiro nome do cliente

          return {
            id: reservation.id,
            title: `${customerFirstName} - ${court.name}`, // Concatena o primeiro nome do cliente com o nome da quadra
            start: new Date(reservation.date + "T" + availability.startTime),
            end: new Date(reservation.date + "T" + availability.endTime),
            allDay: false,
            reservation,
            status: reservation.status,
          };
        })
      );
      setEvents(events);
    };

    getReservations();
  }, []);

  const handleSelectEvent = async (event) => {
    setSelectedEvent(event);
    const customer = await fetchCustomer(event.reservation.idCustomer);
    const court = await fetchCourt(event.reservation.idCourt);
    const availability = await fetchAvailability(
      event.reservation.idAvailability
    );

    setEventDetails({
      customer,
      court,
      availability,
    });
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleCancelReservation = async () => {
    if (window.confirm("Você realmente deseja cancelar a reserva?")) {
      await updateReservationStatus(selectedEvent.id, "CANCELED");
      setEvents(
        events.map((event) =>
          event.id === selectedEvent.id
            ? { ...event, status: "CANCELED" }
            : event
        )
      );
      closeModal();
    }
  };
  const eventStyleGetter = (event, start, end, isSelected) => {
    const backgroundColor = event.status === "CANCELED" ? "red" : "#3174ad";
    const textColor = "#ffffff";

    return {
      style: {
        backgroundColor,
        color: textColor,
        padding: "5px",
        borderRadius: "4px",
        border: "1px solid #ddd", // Borda ao redor de cada evento
        marginBottom: "1px", // Espaço entre os eventos na parte inferior
        marginLeft: "5px", // Espaço à esquerda dos eventos
        marginRight: "5px", // Espaço à direita dos eventos
        marginTop: "1px", // Espaço superior
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Sombra para destacar como um card separado
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
    };
  };

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 1000,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: 1001,
    },
  };

  return (
    <div style={{ height: "90vh" }}>
      <Calendar
        messages={defaultMessages}
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: "100%" }}
        onSelectEvent={handleSelectEvent}
        eventPropGetter={eventStyleGetter}
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Detalhes da Reserva"
      >
        {selectedEvent && eventDetails && (
          <div>
            <h2>Detalhes da Reserva</h2>
            <p>
              <strong>Quadra:</strong> {eventDetails.court.name}
            </p>
            <p>
              <strong>Data:</strong>{" "}
              {moment(selectedEvent.start).format("LLLL")}
            </p>
            <p>
              <strong>Horário:</strong>{" "}
              {moment(eventDetails.availability.startTime, "HH:mm:ss").format(
                "HH:mm"
              )}{" "}
              -{" "}
              {moment(eventDetails.availability.endTime, "HH:mm:ss").format(
                "HH:mm"
              )}
            </p>
            <p>
              <strong>Cliente:</strong> {eventDetails.customer.name}
            </p>
            <p>
              <strong>CPF:</strong> {eventDetails.customer.document}
            </p>
            <p>
              <strong>Telefone:</strong>{" "}
              <a
                href={`https://wa.me/55${eventDetails.customer.phone.replace(
                  /\D/g,
                  ""
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {eventDetails.customer.phone}
              </a>
            </p>
            <button className="cancel-button" onClick={handleCancelReservation}>
              Cancelar a Reserva
            </button>
            <button className="close-button" onClick={closeModal}>
              Fechar
            </button>
          </div>
        )}
      </Modal>
    </div>
  );
}
