import WhatsApp from "@mui/icons-material/WhatsApp";
import PlaceIcon from "@mui/icons-material/Place";

export default function ContactInfo() {
  return (
    <div className="ContactInfo">
      <div>
        <br></br>
        <WhatsApp />
        <a href="https://wa.me/5514996897928" target="_blank">
          {" "}
          (14) 99689-7928
        </a>
      </div>
      <div>
        <br></br>
        <PlaceIcon />
        <a href="https://maps.app.goo.gl/2TzYqnswJUwgEWwd6" target="_blank">
          Como Chegar
        </a>
      </div>
    </div>
  );
}
