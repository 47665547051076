import React, { useState, useEffect } from "react";
import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const AlertUtil = ({ triggerAlert, alertMessage, alertType, onClose }) => {
  useEffect(() => {
    if (triggerAlert) {
      setTimeout(() => {
        onClose();
      }, 30000); // Fecha o alerta automaticamente após 5 segundos
    }
  }, [triggerAlert, onClose]);

  return (
    <Collapse in={triggerAlert}>
      <Alert
        severity={alertType}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={onClose}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
      >
        {alertMessage}
      </Alert>
    </Collapse>
  );
};

export default AlertUtil;
