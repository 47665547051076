import React from "react";
import logo from "../orla_white_navbar.png";
import { NavLink } from "react-router-dom";

export default function PublicNavbar() {
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark bg-custom">
        <style>{`
          .bg-custom {
            background-color: #274C58; /* Aqui está a definição da cor */
            z-index: 1000;
          }
          .nav-link.active {
            color: #f8f9fa; /* Define a cor da link ativo */
          }
        `}</style>
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img
              src={logo}
              alt="Logo"
              style={{ width: "60px", height: "auto" }}
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            {" "}
            {/* Corrigido aqui */}
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink className="nav-link" exact to="/">
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/reservar">
                  Reservar
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/minhas-reservas">
                  Minhas Reservas
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" exact to="/meus-lances">
                  Meus Lances
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/parceiros">
                  Parceiros
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
