import axios from "axios";
import { useState, useEffect } from "react";
import AlertUtil from "../util/AlertUtil"; // Importando o utilitário de alerta
import "../components/css/Court.css";
import API_URL from "../config";

export default function Reservation() {
  const [reservations, setReservations] = useState([]);
  const [reservationDetails, setReservationDetails] = useState([]);
  const [reservationCount, setReservationCount] = useState(0);
  const [cpf, setCpf] = useState(""); // Estado para armazenar o CPF
  const [loading, setLoading] = useState(false); // Estado de loading
  const [submitted, setSubmitted] = useState(false); // Estado para verificar se o CPF foi enviado
  const [errorMessage, setErrorMessage] = useState(""); // Estado para mensagem de erro

  const handleCpfChange = (event) => {
    setCpf(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Evitar o comportamento padrão do formulário
    setLoading(true);
    setSubmitted(true); // Indica que o CPF foi enviado
    await loadReservations(); // Chama a função para carregar reservas com o CPF
    setLoading(false);
  };

  const loadReservations = async () => {
    try {
      const result = await axios.get(
        `${API_URL}/api/public/reservation/document?document=${cpf}` // Usa o CPF na chamada
      );

      const reservationsData = result.data;

      if (reservationsData.length === 0) {
        // Verifica se o array retornado está vazio
        setErrorMessage(
          "Não foram encontradas reservas ativas ou elegíveis para o programa de fidelidade para este CPF."
        );
        setSubmitted(false); // Reseta o estado para que o formulário de CPF apareça novamente
        return; // Interrompe a execução da função
      }

      setErrorMessage(""); // Limpa a mensagem de erro, se houver
      const detailedReservations = await Promise.all(
        reservationsData.map(async (reservation) => {
          const customer = await fetchCustomer(reservation.idCustomer);
          const availability = await fetchAvailability(
            reservation.idAvailability
          );
          const court = await fetchCourt(reservation.idCourt);

          // Montando os detalhes da reserva
          return {
            ...reservation,
            customerId: reservation.idCustomer,
            customerName: customer.name,
            courtName: court.name,
            time: availability.startTime,
            date: reservation.date,
          };
        })
      );

      // Ordenar as reservas por data e horário
      detailedReservations.sort((a, b) => {
        const dateA = new Date(`${a.date}T${a.time}`);
        const dateB = new Date(`${b.date}T${b.time}`);
        return dateB - dateA; // Ordena de forma decrescente
      });

      setReservationDetails(detailedReservations);
    } catch (error) {
      console.error("Erro ao carregar reservas:", error);
      setErrorMessage("Erro ao carregar reservas. Por favor, tente novamente."); // Mensagem de erro genérica
    }
  };

  const fetchCustomer = async (customerId) => {
    const response = await fetch(
      `${API_URL}/api/public/customer/${customerId}`
    );
    const customerData = await response.json();
    setReservationCount(customerData.reservationCount); // Obtendo e armazenando o reservationCount
    return customerData;
  };

  const fetchAvailability = async (availabilityId) => {
    const response = await fetch(
      `${API_URL}/api/public/availability/${availabilityId}`
    );
    return response.json();
  };

  const fetchCourt = async (courtId) => {
    const response = await fetch(`${API_URL}/api/public/court/${courtId}`);
    return response.json();
  };

  // Função para cancelar a reserva
  const handleCancelReservation = async (reservationId, customerId) => {
    if (window.confirm("Você realmente deseja cancelar a reserva?")) {
      await updateReservationStatus(reservationId, "CANCELED");

      // Atualizando a contagem de reservas
      await updateReservationCount(customerId);

      const updatedReservations = reservationDetails.map((reservation) =>
        reservation.id === reservationId
          ? { ...reservation, status: "CANCELED" }
          : reservation
      );
      setReservationDetails(updatedReservations);
    }
  };

  const updateReservationStatus = async (reservationId, status) => {
    const response = await fetch(
      `${API_URL}/api/public/reservation/${reservationId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ status }),
      }
    );
    return response.json();
  };

  const updateReservationCount = async (customerId) => {
    const result = await axios.get(
      `${API_URL}/api/public/customer/${customerId}`
    );
    const customerData = result.data;
    setReservationCount(customerData.reservationCount);
  };

  // Função para verificar se o botão "Cancelar" deve ser habilitado
  const isCancellable = (reservationDate, reservationTime, status) => {
    const reservationDateTime = new Date(
      `${reservationDate}T${reservationTime}`
    );
    const now = new Date();
    const timeDifference = reservationDateTime - now;
    return timeDifference >= 0.1 * 60 * 60 * 1000 && status !== "CANCELED";
  };

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}/${month}/${year}`;
  };

  const formatTime = (timeString) => {
    const [hour] = timeString.split(":");
    const startHour = parseInt(hour, 10);
    const endHour =
      startHour === 23 ? "00" : (startHour + 1).toString().padStart(2, "0");
    return `${startHour}:00h - ${endHour}:00h`;
  };

  return (
    <div className="container">
      <form onSubmit={handleSubmit} style={{ marginTop: "30px" }}>
        <h3>Digite seu CPF</h3>
        <input
          type="text"
          value={cpf}
          onChange={handleCpfChange}
          placeholder="CPF"
          required
          className="input-cpf" // Adiciona a classe CSS aqui
        />
        <button type="submit" className="btn btn-primary mx-2">
          Buscar Reservas
        </button>
      </form>
      <br></br>
      {errorMessage && (
        <div className="alert alert-warning" role="alert">
          {errorMessage}
        </div>
      )}
      {loading && <p>Carregando reservas...</p>} {/* Mensagem de loading */}
      {submitted && reservationDetails.length > 0 && (
        <>
          <section style={{ marginTop: "30px" }}>
            <h2>Reservas</h2>
            <p>
              Total de reservas elegíveis ao programa de fidelidade:{" "}
              {reservationCount === 11 ? 0 : reservationCount}
            </p>
            {/*<p className="text-danger">
              Aviso: As reservas só poderão ser canceladas com mais de 5 horas
              de antecedência.
            </p>*/}
          </section>
          <table className="table border shadow">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Nome do Cliente</th>
                <th scope="col">Quadra</th>
                <th scope="col">Data</th>
                <th scope="col">Horário</th>
                <th scope="col">Status</th>
                <th scope="col">Ações</th>
              </tr>
            </thead>
            <tbody>
              {reservationDetails.map((reservation) => (
                <tr key={reservation.id}>
                  <td>{reservation.id}</td>
                  <td>{reservation.customerName}</td>
                  <td>{reservation.courtName}</td>
                  <td>{formatDate(reservation.date)}</td>
                  <td>{formatTime(reservation.time)}</td>
                  <td>
                    {reservation.status === "APPROVED"
                      ? "Aprovada"
                      : reservation.status === "CANCELED"
                      ? "Cancelada"
                      : "Pendente"}
                  </td>
                  <td>
                    <button
                      className="btn btn-danger mx-2"
                      disabled={
                        !isCancellable(
                          reservation.date,
                          reservation.time,
                          reservation.status
                        )
                      }
                      onClick={() =>
                        handleCancelReservation(
                          reservation.id,
                          reservation.customerId
                        )
                      }
                    >
                      Cancelar
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
}
