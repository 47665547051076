export default function AdditionalInfo() {
  return (
    <div className="additional-info">
      <p>
        Valor das quadras: <strong>R$60,00</strong>
        <p>Pagamento deve ser realizado no local.</p>
      </p>
      <p>
        Não cobramos aluguel de raquetes e bolinhas (6 bolinhas por quadra).
      </p>
      <p>
        Todo <strong>SÁBADO</strong>, 08:30h às 21:30h -{" "}
        <strong>DAY USE</strong> R$20,00 (grátis para alunos matriculados).
      </p>
    </div>
  );
}
